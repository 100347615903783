<template>
  <TheHeader v-if="isLoaded" />
  <TheLoader v-if="!isLoaded" />
  <router-view v-if="isLoaded" />
  <TheFooter v-if="isLoaded" />
</template>

<script>
import TheHeader from "@/components/HeaderFooter/TheHeader.vue";
import TheFooter from "@/components/HeaderFooter/TheFooter.vue";
import TheLoader from "@/components/Functionality/TheLoader.vue";
export default {
  components: {
    TheHeader,
    TheFooter,
    TheLoader,
  },
  mounted() {
    window.scrollTo(0, 0);
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoaded = true;
      }
    };
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    pageTitle() {
      // Získa aktuálnu trasu
      const route = this.$route;
      // Získa titulok z aktuálnej trasy
      return route.meta && route.meta.title
        ? route.meta.title
        : "Bezpečnostné komíny | Vložkovanie komínov, frézovanie a dalšie...";
    },
  },
  watch: {
    $route() {
      // Pri zmene cesty aktualizujte title stránky
      document.title = this.pageTitle;
    },
  },
};
</script>

<style lang="scss">
// IMPORT GOOGLE FONTS

// Hammersmith One
@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap");

//IMPORT CSS

// IMPORT GLOBAL COLORS
@import "@/assets/sass/_colors.scss";
// IMPORT GLOBAL Styles
@import "@/assets/sass/globalStyles.scss";
// IMPORT DEFAULT Styles
@import "@/assets/sass/_defaultStyles.scss";

#overlay {
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #0f0f0fed;
  z-index: 101;
  position: fixed;
}

#overlay img {
  width: 80%;
  height: auto;
  object-fit: contain;
}
</style>
