import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "Uvod",
    component: () => import("../views/Uvod.vue"),
  },
  {
    path: "/Sluzby",
    name: "Sluzby",
    component: () => import("../views/Sluzby.vue"),
    meta: { title: "Služby | Vložkovanie komínov, frézovanie a dalšie..." },
  },
  {
    path: "/Cennik",
    name: "Cennik",
    component: () => import("../views/Cennik.vue"),
    meta: { title: "Cenník | Vložkovanie komínov, frézovanie a dalšie..." },
  },
  {
    path: "/Galeria",
    name: "Galeria",
    component: () => import("../views/Galeria.vue"),
    meta: { title: "Galéria | Vložkovanie komínov, frézovanie a dalšie..." },
  },
  {
    path: "/Kontakt",
    name: "Kontakt",
    component: () => import("../views/Kontakt.vue"),
    meta: { title: "Kontakt | Vložkovanie komínov, frézovanie a dalšie..." },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404View.vue"),
    meta: {
      title: "Niečo sa pokazilo | Vložkovanie komínov, frézovanie a dalšie...",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Pridajte gtag hook pre sledovanie stránok po každej zmene
router.afterEach((to) => {
  this.app.config.globalProperties.$gtag.page({
    page_path: to.path,
  });
});

export default router;
