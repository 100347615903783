import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { MotionPlugin } from "@vueuse/motion";
import VueGtag from "vue-gtag";

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

const app = createApp(App);

// Použite vue-gtag s vaším Google Analytics Tracking ID
app.use(
  VueGtag,
  {
    config: { id: "G-EC5R8QJMMN" },
    appName: "Bezpečnostné komíny", // Nastavte podľa potreby
    pageTrackerScreenviewEnabled: true,
  },
  router
);

app.use(MotionPlugin).use(store).use(router).mount("#app");
