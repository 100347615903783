<template>
  <footer>
    <div v-motion-slide-visible-once-left class="logo">
      <router-link to="/"
        ><img src="@/assets/images/logoLong.svg" alt="Logo"
      /></router-link>
    </div>
    <div v-motion-slide-visible-once-top class="menu">
      <ul>
        <li v-motion-pop><router-link to="/">Úvod</router-link></li>
        <li v-motion-pop :delay="100">
          <router-link to="/Sluzby">Služby</router-link>
        </li>
        <li v-motion-pop :delay="200">
          <router-link to="/Cennik">Cenník</router-link>
        </li>
        <li v-motion-pop :delay="300">
          <router-link to="/Galeria">Galéria</router-link>
        </li>
        <li v-motion-pop :delay="400">
          <router-link to="/Kontakt">Kontakt</router-link>
        </li>
      </ul>
    </div>
    <div v-motion-slide-visible-once-right :delay="300" class="links">
      <a target="_blank" href="certifikat.pdf">Certifikát</a>
    </div>
  </footer>
  <div class="small-footer">
    <p v-motion-slide-visible-once-left :delay="500">
      Všetky práva vyhradené &copy; {{ date }}
    </p>
    <p class="pc" v-motion-fade-visible-once :delay="500">&nbsp; | &nbsp;</p>
    <p v-motion-slide-visible-once-right :delay="500">
      Web by <a target="_blank" href="https://www.luboskukla.sk">Ľuboš Kukla</a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
// IMPORT COLORS
@import "@/assets/sass/_colors.scss";

footer {
  display: flex;
  padding: 2rem 2%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: $bg-clr;
  z-index: 100;
  position: relative;
}

.small-footer {
  background: $bg-clr;
  z-index: 100;
  position: relative;
}

.logo {
  width: 20%;

  a img {
    width: 100%;
  }
}

.links {
  width: 20%;

  a {
    color: rgba($txt-clr, 50%);

    &:hover {
      color: $txt-clr;
    }
  }
}

.menu {
  width: 50%;

  ul {
    display: flex;
    justify-content: center;
    gap: 4%;
    font-size: 1rem;
    li a {
      color: rgba($txt-clr, 50%);

      &:hover {
        color: $txt-clr;
      }
    }

    li .router-link-active.router-link-exact-active {
      color: $txt-clr;
    }
  }
}

.small-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;

  a {
    color: $scnd-clr;
  }
}

@media screen and (max-width: 800px) {
  footer {
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    padding: 2rem 5%;
  }
  .logo,
  .menu,
  .links {
    width: auto;
  }
}

@media screen and (max-width: 500px) {
  .small-footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      padding: 0.5rem;
    }
  }

  .menu ul {
    gap: 3%;
  }

  .pc {
    display: none;
  }
}
</style>
